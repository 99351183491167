import React, { useState, useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"
import classnames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { Button } from "./buttons"
import * as styles from "../styles/header.module.css"
import { mainMenu } from "../utils/constants"
import { AppContext } from "../utils/context"

const { initial, animate, exit } = mainMenu.animations

const Header = ({ menuItems, siteTitle, activeMenuId, yPos }) => {
    const [pathName, setPathName] = useState("")
    const [activeMenu, setActiveMenu] = useState()
    const [isSearchVisible, setIsSearchVisible] = useState(false)
    const { isMobileMenuVisible, setIsMobileMenuVisible } =
        useContext(AppContext)
    const toggleSubMenu = (e, item) => {
        e.preventDefault()
        if (isSearchVisible) setIsSearchVisible(false)
        const newValue = item.id !== activeMenu ? item.id : null
        setActiveMenu(newValue)
    }
    const toggleSearch = () => {
        if (!!activeMenu) setActiveMenu()
        setIsSearchVisible(!isSearchVisible)
    }
    const toggleMobileMenu = () => {
        setIsMobileMenuVisible(!isMobileMenuVisible)
    }
    const headerClass = classnames(
        `z-50 fixed top-0 inset-x-0 transition-all ${styles.header}`,
        {
            "bg-white": yPos > 25,
            "bg-transparent": yPos <= 25,
        }
    )
    const handleLogin = () => {
        navigate("/login")
    }
    const getPathname = (url = null) => {
        if (typeof window !== "undefined") {
            return url.split("/")[1]
        }
    }
    useEffect(() => {
        setPathName(window.location.pathname.split("/")[1])
    }, [])
    return (
        <header className={headerClass}>
            <div className="container mx-auto">
                <div className="flex justify-center lg:justify-between items-center px-4">
                    <h1>
                        <Link className={styles.logo} to="/">
                            {siteTitle}
                        </Link>
                    </h1>
                    <div className="flex nav-wrapper justify-end items-center">
                        <nav className="hidden lg:block">
                            <ul>
                                {menuItems?.nodes.map((item, i) => {
                                    const withChildren =
                                        item?.childItems?.nodes?.length > 0
                                    const isExternalLink =
                                        item?.url.indexOf("http") >= 0
                                    const itemLiClass = classnames(
                                        "inline-block relative",
                                        {
                                            [styles.hasChildren]: withChildren,
                                        }
                                    )
                                    const itemClass = classnames(
                                        `block py-10 text-sm lg:text-base`,
                                        styles.menuItem,
                                        {
                                            [styles.activeMenuItem]:
                                                pathName ==
                                                getPathname(item.url),
                                            "md:px-2 px-6": !withChildren,
                                            "md:pl-2 md:pr-8 pl-6 pr-10":
                                                withChildren,
                                        }
                                    )
                                    return (
                                        <li
                                            key={`mi-${i}`}
                                            className={itemLiClass}
                                        >
                                            {withChildren ? (
                                                // menu with children
                                                <button
                                                    className={itemClass}
                                                    onClick={e =>
                                                        toggleSubMenu(e, item)
                                                    }
                                                >
                                                    {item.label}
                                                </button>
                                            ) : item?.parentId == null ? (
                                                // menu without children
                                                isExternalLink ? (
                                                    <a
                                                        className={itemClass}
                                                        href={item.url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {item.label}
                                                    </a>
                                                ) : (
                                                    <Link
                                                        className={itemClass}
                                                        to={item.url}
                                                    >
                                                        {item.label}
                                                    </Link>
                                                )
                                            ) : (
                                                ""
                                            )}
                                            <AnimatePresence>
                                                {withChildren > 0 &&
                                                    activeMenu === item.id && (
                                                        <motion.ul
                                                            className={
                                                                styles.children
                                                            }
                                                            initial={initial}
                                                            animate={animate}
                                                            exit={exit}
                                                        >
                                                            {item?.childItems?.nodes.map(
                                                                (child, c) => {
                                                                    const isChildExternalUrl =
                                                                        child.url.indexOf(
                                                                            "http"
                                                                        ) >= 0
                                                                    return (
                                                                        <li
                                                                            key={`mc-${c}`}
                                                                        >
                                                                            {isChildExternalUrl ? (
                                                                                <a
                                                                                    href={
                                                                                        child.url
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    {
                                                                                        child.label
                                                                                    }
                                                                                </a>
                                                                            ) : (
                                                                                <Link
                                                                                    to={
                                                                                        child.url
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        child.label
                                                                                    }
                                                                                </Link>
                                                                            )}
                                                                        </li>
                                                                    )
                                                                }
                                                            )}
                                                        </motion.ul>
                                                    )}
                                            </AnimatePresence>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                        <button
                            className={`hidden lg:block ${styles.search}`}
                            onClick={toggleSearch}
                        >
                            Search
                        </button>
                        <div className="absolute left-0 lg:static">
                            <Button onClick={handleLogin}>Login</Button>
                        </div>
                        <div className="absolute block lg:hidden right-4">
                            <button
                                className={styles.hamburger}
                                onClick={toggleMobileMenu}
                            >
                                Mobile Menu
                            </button>
                        </div>
                    </div>
                </div>
                <AnimatePresence>
                    {isSearchVisible && (
                        <motion.form
                            className={styles.searchForm}
                            initial={initial}
                            animate={animate}
                            exit={exit}
                            method="GET"
                            action="/search"
                        >
                            <div>
                                <input
                                    name="q"
                                    type="text"
                                    placeholder="Type to search"
                                />
                            </div>
                        </motion.form>
                    )}
                </AnimatePresence>
            </div>
        </header>
    )
}

export default Header
