// extracted by mini-css-extract-plugin
export var mainFooter = "footer-module--mainFooter--3edE8";
export var footerLogo = "footer-module--footerLogo--1GuMV";
export var socialLinks = "footer-module--socialLinks--2zhqy";
export var fb = "footer-module--fb--1yUHW";
export var ig = "footer-module--ig--3nKmE";
export var ln = "footer-module--ln--3byRN";
export var tw = "footer-module--tw--1Cezy";
export var yt = "footer-module--yt--1vuXE";
export var line = "footer-module--line--3UkUu";
export var tiktok = "footer-module--tiktok--259tH";
export var footerLinks = "footer-module--footerLinks--39p7m";
export var inquiry = "footer-module--inquiry--4o4_0";
export var wa = "footer-module--wa--4Ys1j";
export var phone = "footer-module--phone--6yHHK";
export var email = "footer-module--email--3lqKY";
export var ribbon = "footer-module--ribbon--2Ivtv";