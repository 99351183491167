import React, { useState, useContext, useLayoutEffect, useEffect } from "react"
import { Link, navigate } from "gatsby"
import classnames from "classnames"
import * as styles from "../styles/header.module.css"
import { AppContext } from "../utils/context"
import { useAuthContext } from "../context/authContext"

const HeaderSap = ({ menuItems, siteTitle, activeMenuId, yPos }) => {
    const { user, setUser } = useAuthContext()
    const [loggedIn, setLoggedIn] = useState(user !== null)

    const { isMobileMenuVisible, setIsMobileMenuVisible } =
        useContext(AppContext)
    const toggleMobileMenu = () => {
        setIsMobileMenuVisible(!isMobileMenuVisible)
    }
    const headerClass = classnames(
        `z-50 fixed top-0 inset-x-0 transition-all ${styles.header}`,
        {
            "bg-white": yPos > 25,
            "bg-transparent": yPos <= 25
        }
    )
    // start SAP
    const sapKey = process.env.SAP_API_KEY

    useLayoutEffect(() => {
        // load SAP cdn at header
        ;(function loadSAPCdn() {
            var script = document.createElement("script")
            script.src = `https://cdns.gigya.com/js/gigya.js?apikey=${sapKey}`
            document
                .getElementsByTagName("script")[0]
                .parentNode.appendChild(script)
        })()
    }, [])

    useEffect(() => {
        function getAccountInfoResponse(response) {
            if (response.errorCode == 0) {
                // $("#lblName").text(msg);
                window.gigya.accounts.showScreenSet({
                    screenSet: "SML-ProfileUpdate",
                    startScreen: "gigya-update-profile-screen",
                    containerID: "mainProfile",
                })
                setLoggedIn(true)
                // $("#btnLogin").hide(); // convert to vanilla javascript
                // $("#btnRegister").hide(); // convert to vanilla javascript
            } else {
                //console.log('Error :' + response.errorMessage);
                //$("#lblName").text(response.statusMessage);
            }
            //console.log(response);
        }

        setTimeout(() => {
            window.gigya.accounts.getAccountInfo({
                callback: getAccountInfoResponse,
            })
            window.gigya.accounts.addEventHandlers({
                onLogin: eventData => {
                    setUser(eventData.profile)
                    navigate("/profile")
                },
            })
        }, 5000)
    }, [])

    useEffect(() => {
        setLoggedIn(user !== null)
    }, [user])

    const logoutResponse = response => {
        if (response.errorCode == 0) {
            navigate("/login")
            setUser(null)
        } else {
            alert("Error :" + response.errorMessage)
        }
    }

    const handlerLogout = () => {
        window.gigya.accounts.logout({
            callback: logoutResponse,
        })
    }

    // end SAP

    const itemLiClass = classnames("inline-block relative", {
        [styles.hasChildren]: false,
    })
    const itemClass = classnames(
        `block py-10 text-sm lg:text-base`,
        styles.menuItem,
        {
            [styles.activeMenuItem]: false,
            "md:px-2 px-6": true,
            "md:pl-2 md:pr-8 pl-6 pr-10": false,
        }
    )

    return (
        <header className={headerClass}>
            <div className="container mx-auto">
                <div className="flex justify-center lg:justify-between items-center px-4">
                    <h1>
                        <Link className={styles.logo} to="/">
                            {siteTitle}
                        </Link>
                    </h1>
                    <div className="flex nav-wrapper justify-end items-center">
                        <nav className="hidden lg:block">
                            <ul>
                                {loggedIn ? (
                                    <>
                                        <li className={itemLiClass}>
                                            <Link
                                                className={itemClass}
                                                to={"#"}
                                            >
                                                {`Hi ${user?.lastName},  Welcome back`}
                                            </Link>
                                        </li>
                                        <li className={itemLiClass}>
                                            <Link
                                                className={itemClass}
                                                to={"/profile"}
                                            >
                                                Profile
                                            </Link>
                                        </li>
                                        <li className={itemLiClass}>
                                            <Link
                                                className={itemClass}
                                                to={"#"}
                                                onClick={handlerLogout}
                                            >
                                                Logout
                                            </Link>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className={itemLiClass}>
                                            <Link
                                                className={itemClass}
                                                to={"/login"}
                                            >
                                                Login
                                            </Link>
                                        </li>
                                        <li className={itemLiClass}>
                                            <Link
                                                className={itemClass}
                                                to={"/register"}
                                            >
                                                Register
                                            </Link>
                                        </li>
                                        <li className={itemLiClass}>
                                            <Link
                                                className={itemClass}
                                                to={"/subscribe"}
                                            >
                                                Subscribe
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </nav>
                        <div className="absolute block lg:hidden right-4">
                            <button
                                className={styles.hamburger}
                                onClick={toggleMobileMenu}
                            >
                                Mobile Menu
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderSap
