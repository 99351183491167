import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"
import classnames from "classnames"

import Header from "./header"
import HeaderSap from "./headerSap"
import Footer from "./footer"
import Testimonials from "./testimonials"
import Promo from "./promo"
import MobileMenu from "./mobilemenu"
import MobileMenuSap from "./mobilemenuSap"

import useScroll from "../utils/useScroll"
import { getUtmParams } from "../utils/handle-utm"
import { useLangContext } from "../context/langContext"

const Layout = ({
    activeMenuId,
    isTestimonialsHidden,
    isWhiteBg,
    children,
    isSap = false,
}) => {
    const { yPos } = useScroll()
    const [isToTopVisible, setIsToTopVisible] = useState(false)
    const { lang } = useLangContext()

    useEffect(() => {
        setIsToTopVisible(yPos > 500)
    }, [yPos])
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
            allWpMenu {
                nodes {
                    name
                    menuItems {
                        nodes {
                            id
                            label
                            url
                            parentId
                            childItems {
                                nodes {
                                    id
                                    label
                                    url
                                }
                            }
                        }
                    }
                }
            }
            allWpTestimony {
                nodes {
                    blocks {
                        name
                        ... on WpAcfAcfimageBlock {
                            imageBlock {
                              img {
                                altText
                                caption
                                sourceUrl
                                mediaItemUrl
                              }
                            }
                        }
                        ... on WpCorePullquoteBlock {
                            attributes {
                                ... on WpCorePullquoteBlockAttributes {
                                    citation
                                    value
                                }
                            }
                        }
                    }
                }
            }
            wp {
                generalSettings {
                    officePhone
                    officeEmail
                    officeWhatsapp
                }
            }
        }
    `)

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" })

    const mainClass = classnames({
        "bg-white": isWhiteBg,
    })

    useEffect(() => {
        getUtmParams(window.location.search)
    }, [])

    let menuItems
    if (lang === "en") {
        menuItems = data?.allWpMenu?.nodes.find(
            i => i?.name === "Navbar Menu"
        )?.menuItems
    } else {
        menuItems = data?.allWpMenu?.nodes.find(i => i?.name === "Navbar Menu ID")
            ?.menuItems
    }

    return (
        <>
            {isSap ? (
                <HeaderSap
                    yPos={yPos}
                    siteTitle={data.site.siteMetadata?.title || `Title`}
                />
            ) : (
                <Header
                    yPos={yPos}
                    siteTitle={data.site.siteMetadata?.title || `Title`}
                    activeMenuId={activeMenuId}
                    menuItems={menuItems}
                />
            )}
            <div>
                <main className={mainClass}>{children}</main>
                {!isTestimonialsHidden && (
                    <Testimonials data={data?.allWpTestimony?.nodes} />
                )}
                <Footer
                    menuItems={data?.allWpMenu?.nodes}
                    settings={data?.wp?.generalSettings}
                />
            </div>
            <Promo />
            {isSap ? (
                <MobileMenuSap
                    activeMenuId={activeMenuId}
                    menuItems={menuItems}
                />
            ) : (
                <MobileMenu activeMenuId={activeMenuId} menuItems={menuItems} />
            )}
            <motion.button
                animate={{
                    y: isToTopVisible ? -375 : 0,
                }}
                transition={{ type: "tween" }}
                className="back-to-top"
                onClick={scrollToTop}
            >
                Back To Top
            </motion.button>
        </>
    )
}

export default Layout
