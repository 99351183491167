import { useState, useEffect } from "react"

export default function useScroll() {
  const [yPos, setYPos] = useState(0)
  const [isDown, setIsDown] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset
      setYPos(currentScroll)
      setIsDown(currentScroll > yPos)
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [yPos, setYPos, isDown, setIsDown])
  return { yPos, isDown }
}
