import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classnames from "classnames"
import Slider from "react-slick"
import { motion } from "framer-motion"
import * as styles from "../styles/promo.module.css"

const Promo = () => {
    const data = useStaticQuery(graphql`
        query Promo {
            allWpPromotion {
                nodes {
                    promotionFields {
                        image {
                            altText
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    `)
    const slideWrapRef = useRef()
    const slideRef = useRef()
    const [slideHeight, setSlideHeight] = useState(0)
    const [isVisible, setIsVisible] = useState(false)
    const [opacity, setOpacity] = useState(0)
    const toggle = () => {
        const slideSize =
            slideWrapRef && slideWrapRef.current
                ? slideWrapRef.current.getBoundingClientRect()
                : null
        const targetHeight = slideHeight === 0 ? slideSize.height : 0
        setSlideHeight(targetHeight)
        setIsVisible(!isVisible)
    }
    const goToPrev = () => {
        if (slideRef) slideRef.current.slickPrev()
    }
    const goToNext = () => {
        if (slideRef) slideRef.current.slickNext()
    }
    const settings = {
        arrows: false,
        dots: false,
        centerPadding: "200px",
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: "unslick",
            },
        ],
    }
    const onSlideInit = () => {
        setSlideHeight("calc(100% - 25px)")
        setTimeout(() => {
            setOpacity(1)
        }, 500)
    }
    useEffect(() => {
        const checkSlider = () => {
            if (slideWrapRef && slideWrapRef.current) {
                const { height } = slideWrapRef.current.getBoundingClientRect()
                setSlideHeight(height)
                setOpacity(1)
            }
        }
        window.addEventListener("resize", checkSlider)
        return () => window.removeEventListener("resize", checkSlider)
    }, [])
    const wrapClass = classnames("fixed bottom-0 right-0 left-0", styles.promo)
    let slideItems = data?.allWpPromotion?.nodes || []
    return (
        <motion.div
            animate={{ opacity, y: slideHeight }}
            transition={{ type: "tween" }}
            className={wrapClass}
        >
            <button
                className={`flex justify-center items-center absolute ${styles.togglePromo}`}
                onClick={toggle}
            >
                {isVisible ? (
                    <>
                        <span className="block">
                            Click here to close our latest promotions
                        </span>
                        <span className={styles.promoClose} />
                    </>
                ) : (
                    <>
                        <span className="block">
                            Click here to see our latest promotions
                        </span>
                        <span className={styles.promoStar} />
                    </>
                )}
            </button>
            <div className="slides-wrap" ref={slideWrapRef}>
                <Slider
                    ref={slideRef}
                    {...settings}
                    onInit={onSlideInit}
                >
                    {slideItems.map((item, i) => {
                        const promo = item?.promotionFields
                        return (
                            <div key={i} className="promo-item">
                                <div className="flex justify-center items-center">
                                    <img
                                        src={promo?.image?.mediaItemUrl}
                                        alt={promo?.image?.altText}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </Slider>
                <button
                    className={`hidden lg:block ${styles.arrowLeft} ${styles.promoArrow}`}
                    onClick={goToPrev}
                >
                    Previous
                </button>
                <button
                    className={`hidden lg:block ${styles.arrowRight} ${styles.promoArrow}`}
                    onClick={goToNext}
                >
                    Next
                </button>
            </div>
        </motion.div>
    )
}

export default Promo
