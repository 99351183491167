export function replaceTag(text) {
    return text?.replace(/(<([^>]+)>)/gi, "")
}

export function formatDate(date = new Date()) {
    let theDate = typeof date === "string" ? new Date(date) : date
    const month = `0${theDate.getMonth() + 1}`.slice(-2)
    const formattedDate = `${theDate.getFullYear()}-${month}-${theDate.getDate()} ${theDate.getHours()}:${theDate.getMinutes()}:${theDate.getSeconds()}`
    return formattedDate
}

export function extractSrcFromIframe(iframeScript) {
    const iframe = iframeScript
    let sp = iframe.split(" ")
    let src = sp.find(i => i.includes("src"))
    let splitSrc = src.split('="')

    return splitSrc[1].split('"')[0]
}

export function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5)
}
