export const mainMenu = {
    items: [
        { id: 1, link: "/", label: "Home" },
        {
            id: 2,
            link: "/about",
            label: "About Us",
            children: [
                { link: "/about", label: "About Kota Wisata" },
                { link: "/about/sustainability", label: "Sustainability" },
            ],
        },
        {
            id: 3,
            link: "#",
            label: "Developments",
            children: [
                { link: "/developments/type", label: "Residential" },
                { link: "/developments/type", label: "Office" },
                { link: "/developments/type", label: "Commercial" },
                { link: "/developments/type", label: "Industrial" },
                { link: "/developments/type", label: "Future Development" },
            ],
        },
        { id: 4, link: "/facilities", label: "Facilities" },
        { id: 5, link: "/news-events", label: "News & Events" },
        { id: 6, link: "/access", label: "Access" },
        { id: 7, link: "/services", label: "Services" },
        { id: 8, link: "/contact-us", label: "Contact Us" },
        { id: 9, link: "/e-catalog", label: "E-Catalog" },
    ],
    animations: {
        initial: { opacity: 0, top: 74 },
        animate: { opacity: 1, top: 84 },
        exit: { opacity: 0, top: 74 },
    },
}

export const sapMenu = {
    items: [
        { id: 1, link: "/login", label: "Login" },
        { id: 2, link: "/register", label: "Register" },
        { id: 3, link: "/subscribe", label: "Subscribe" },
    ],
    animations: {
        initial: { opacity: 0, top: 74 },
        animate: { opacity: 1, top: 84 },
        exit: { opacity: 0, top: 74 },
    },
}

export const devPageLinks = [
    { link: "/developments/type/single", label: "Types" },
    { link: "/developments/type/siteplan", label: "Siteplan" },
    { link: "/developments/type/getmoreinfo", label: "Get more info" },
]
