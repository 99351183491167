import * as React from "react"
import { Link } from "gatsby"
import * as styles from "../styles/footer.module.css"

import { useLangContext } from "../context/langContext"

const formatPhone = phone => {
    return phone.replaceAll("+", "")
}

const Footer = ({ menuItems, settings }) => {
    const { lang, setLang } = useLangContext()
    const year = new Date().getFullYear()
    let footerMenu
    if (lang === "en") {
        footerMenu = menuItems?.find(i => i.name === "Footer Menu").menuItems
    } else {
        footerMenu = menuItems?.find(i => i.name === "Footer Menu ID").menuItems
    }
    return (
        <footer className={`relative pt-16 pb-52 ${styles.mainFooter}`}>
            <div className="container mx-auto">
                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-1/5 text-center">
                        <Link to="/" className={styles.footerLogo}>
                            Kota Wisata
                        </Link>
                        <ul className={styles.socialLinks}>
                            {menuItems
                                ?.find(i => i.name === "Socmed Menu")
                                .menuItems?.nodes.map((item, i) => {
                                    return (
                                        <li
                                            key={i}
                                            className={styles[item.label]}
                                        >
                                            <a
                                                href={item.url}
                                                aria-label="socmed-link"
                                                target="_blank"
                                                rel="noreferrer"
                                            ></a>
                                        </li>
                                    )
                                })}
                        </ul>
                        {`©️ ${year} Kota Wisata`}
                    </div>
                    <div className="w-full lg:w-4/5 mt-12 lg:mt-0">
                        <div
                            className={`flex flex-col lg:flex-row ${styles.footerLinks}`}
                        >
                            <div className="w-full lg:w-1/4 px-12 lg:px-0">
                                <h3 className="font-serif font-bold mb-4 lg:mb-12">
                                    For Inquiry
                                </h3>
                                <ul className={styles.inquiry}>
                                    <li className={styles.phone}>
                                        <a
                                            href={`tel:${formatPhone(
                                                settings?.officePhone
                                            )}`}
                                        >
                                            {settings?.officePhone}
                                        </a>
                                    </li>
                                    <li className={styles.wa}>
                                        <a
                                            target="_blank"
                                            href={`https://api.whatsapp.com/send?phone=${formatPhone(
                                                settings?.officeWhatsapp
                                            )}`}
                                        >
                                            {settings?.officeWhatsapp}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full lg:w-1/2 mt-12 lg:mt-0 px-12 lg:px-0">
                                <h3 className="font-serif font-bold mb-4 lg:mb-12">
                                    Quick Links
                                </h3>
                                <div className="flex">
                                    {footerMenu?.nodes.map((item, i) => {
                                        if (
                                            item.label === "Quick" ||
                                            item.label === "Quick2"
                                        ) {
                                            return (
                                                <ul key={i} className="flex-1">
                                                    {item?.childItems?.nodes.map(
                                                        c => {
                                                            return (
                                                                <li key={c.id}>
                                                                    <Link
                                                                        to={
                                                                            c.url
                                                                        }
                                                                    >
                                                                        {
                                                                            c.label
                                                                        }
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="w-full lg:w-1/4 mt-12 lg:mt-0 px-12 lg:px-0">
                                <h3 className="font-serif font-bold mb-4 lg:mb-12">
                                    Other
                                </h3>
                                {footerMenu?.nodes.map((item, i) => {
                                    if (item.label === "Other") {
                                        return (
                                            <ul key={i}>
                                                {item?.childItems?.nodes.map(
                                                    c => {
                                                        return (
                                                            <li key={c.id}>
                                                                <Link
                                                                    to={c.url}
                                                                >
                                                                    {c.label}
                                                                </Link>
                                                            </li>
                                                        )
                                                    }
                                                )}
                                              {/*<li>
                                                    <button
                                                        onClick={() =>
                                                            setLang("en")
                                                        }
                                                        style={{
                                                            paddingRight: 10,
                                                        }}
                                                        className={
                                                            lang === "en"
                                                                ? "font-bold"
                                                                : ""
                                                        }
                                                    >
                                                        EN
                                                    </button>
                                                    |
                                                    <button
                                                        onClick={() =>
                                                            setLang("id")
                                                        }
                                                        style={{
                                                            paddingLeft: 10,
                                                        }}
                                                        className={
                                                            lang === "id"
                                                                ? "font-bold"
                                                                : ""
                                                        }
                                                    >
                                                        ID
                                                    </button>
                                                </li>*/}
                                            </ul>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.ribbon} />
        </footer>
    )
}

export default Footer
