import React, { useRef } from "react"
import Slider from "react-slick"
import classnames from "classnames"
import * as styles from "../styles/testimonials.module.css"
import testiImage from "../images/testimonials-img.png"
import { replaceTag } from "../utils/utils.js"

const Testimonials = ({ data }) => {
    const slideRef = useRef()
    const goToPrev = () => {
        if (slideRef) slideRef.current.slickPrev()
    }
    const goToNext = () => {
        if (slideRef) slideRef.current.slickNext()
    }
    const slidesData = [...data]
    const isTestimonialsEmpty = slidesData.length < 1
    
    // adjust isTestimonialsEmpty to show / hide the testimonials block
    const testimonialsBlockClass = classnames("bg-white relative", {
        "py-16 lg:py-0": !isTestimonialsEmpty,
        [styles.testimonials]: !isTestimonialsEmpty,
    })
    return (
        <>
            {isTestimonialsEmpty ? (
                <div className="bg-white pt-2"></div>
            ) : (
                <div className={testimonialsBlockClass}>
                    <Slider ref={slideRef} arrows={false}>
                        {slidesData.map((item, i) => {
                            return (
                                <div key={i}>
                                    <div className="h-full flex justify-between items-center">
                                        {item?.blocks.map((block, b) => {
                                                switch (block?.name) {
                                                    case "acf/acfimage":
                                                        return (
                                                            <div key={b} className="hidden lg:block">
                                                                <div className={styles.image}>
                                                                    <img
                                                                        src={block?.imageBlock?.img?.sourceUrl}
                                                                        alt={block?.imageBlock?.img?.altText}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )

                                                    case "core/pullquote":
                                                        return (
                                                            <div key={b} className="flex-1 mx-4 px-8 text-center">
                                                                <div
                                                                    className={`mx-auto ${styles.text}`}
                                                                >
                                                                    <div
                                                                        key={b}
                                                                        className="px-8 lg:px-4"
                                                                    >
                                                                        <div
                                                                            className={`mb-8 italic font-light text-base lg:text-xl ${styles.testiText}`}
                                                                        >
                                                                            {replaceTag(
                                                                                block
                                                                                    ?.attributes
                                                                                    ?.value
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={`text-base lg:text-xl ${styles.name}`}
                                                                        >
                                                                            {replaceTag(
                                                                                block
                                                                                    ?.attributes
                                                                                    ?.citation
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                            }
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                    <div>
                        <button
                            onClick={goToPrev}
                            className={`inline-block mr-2 ${styles.btn} ${styles.prevBtn}`}
                        >
                            Previous
                        </button>
                        <button
                            onClick={goToNext}
                            className={`inline-block ml-2 ${styles.btn} ${styles.nextBtn}`}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default Testimonials
