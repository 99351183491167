import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { formatDate } from "../utils/utils"

function Seo({
    description = "",
    lang = "en",
    title,
    img = "https://kotawisatacms.coda-technology.com/app/uploads/2021/12/home-cover.jpg",
    imgWidth = 1200,
    imgHeight = 630,
    date,
}) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        fbAppId
                        twitterAccount
                        msValidate
                        yandexSite
                    }
                }
            }
        `
    )

    const location = useLocation()
    const currentUrl = `${site.siteMetadata?.siteUrl}${location.pathname}`
    const metaDescription = description || site.siteMetadata.description
    const metaTitle = title
        ? `${title} | ${site.siteMetadata?.title}`
        : site.siteMetadata?.title
    const metaAuthor = site.siteMetadata?.author || "kota-wisata.com"
    const fbAppId = site.siteMetadata?.fbAppId || "307505239778688"
    const twitterAccount =
        site.siteMetadata?.twitterAccount || "@KotaWisata_SML"
    const msValidate =
        site.siteMetadata?.msValidate || "18CCCD23B3273779A5EB79EA779F9BF1"
    const yandexSite = site.siteMetadata?.yandexSite || "53e3010d629e429e"
    const datePublished = formatDate(date)

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
        >
            {/* GENERAL */}
            <meta name="description" content={metaDescription} />
            <meta content={metaAuthor} name="author" />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="black"
            />
            <meta name="theme-color" content="#0E4372" />
            <meta content={lang} name="language" />
            <meta content="id" name="geo.country" />
            <meta content="Indonesia" name="geo.placename" />
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="all" />
            <meta
                name="csrf-token"
                content="7eg33b5JaaXGAJWvI3eJviO5OqdQBHEvs1Opnh9S"
            />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="apple-touch-fullscreen" content="yes" />
            <meta name="msvalidate.01" content={msValidate} />
            <meta name="yandex-verification" content={yandexSite} />

            {/* FACEBOOK */}
            <meta property="fb:app_id" content={fbAppId} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:site_name" content="kotawisata" />
            <meta property="og:type" content="article" />
            <meta property="article:published_time" content={datePublished} />
            <meta property="og:image" content={img} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content={imgWidth} />
            <meta property="og:image:height" content={imgHeight}/>
            <meta property="og:image:alt" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={currentUrl} />,

            {/* TWITTER */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:image:src" content={img} />
            <meta name="twitter:site" content={twitterAccount} />
            <meta name="twitter:site:id" content={twitterAccount} />
            <meta name="twitter:site:creator" content={twitterAccount} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:url" content={currentUrl} />,

            <meta
                name="google-site-verification"
                content="2vG_TizkYvGNDQcwLPwEGG49iCBfsROo1VsAE-a49G0" />
	    <meta
                name="facebook-domain-verification"
                content="vjnf33ormjuuhvg5csbgxecid7098m" />
            <link rel="canonical" href={currentUrl} />
        </Helmet>
    )
}

export default Seo
