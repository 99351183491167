import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import classnames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import * as styles from "../styles/mobilemenu.module.css"
import { mainMenu } from "../utils/constants"
import { AppContext } from "../utils/context"

const { initial, animate, exit } = mainMenu.animations

const MobileMenu = ({ activeMenuId, menuItems }) => {
    const [activeMenu, setActiveMenu] = useState()
    const { isMobileMenuVisible, setIsMobileMenuVisible } =
        useContext(AppContext)
    const toggleSubMenu = (e, item) => {
        e.preventDefault()
        const newValue = item.id !== activeMenu ? item.id : null
        setActiveMenu(newValue)
    }
    const toggleMobileMenu = () => {
        setIsMobileMenuVisible(!isMobileMenuVisible)
    }
    const goTo = link => {
        setIsMobileMenuVisible(false)
        navigate(link)
    }
    const getPathname = (url = null) => {
        if(typeof window != "undefined") {
            if(url != null) return url.split("/")[1];
            return window.location.pathname.split("/")[1];
        }
    }
    return (
        <AnimatePresence>
            {isMobileMenuVisible && (
                <motion.div
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: "100%", opacity: 0 }}
                    transition={{ type: "tween" }}
                    className={`block lg:hidden fixed inset-0 ${styles.mobileMenu}`}
                >
                    <div className="h-full px-8 pt-16 flex flex-col">
                        <form method="GET" action="/search">
                            <input
                                name="q"
                                type="text"
                                placeholder="Type to search"
                            />
                        </form>
                        <div className="flex-1 overflow-y-auto">
                            <nav>
                                <ul>
                                    {menuItems?.nodes.map((item, i) => {
                                        const withChildren =
                                            item?.childItems?.nodes?.length > 0
                                        const itemClass = classnames(
                                            `block py-10 text-sm lg:text-base`,
                                            {
                                                "font-bold":
                                                    getPathname() ===
                                                    getPathname(item.url),
                                                "md:px-2 px-6": !withChildren,
                                                "md:pl-2 md:pr-8 pl-6 pr-10":
                                                    withChildren,
                                            }
                                        )
                                        return (
                                            <li key={`mi-${i}`}>
                                                {withChildren ? (
                                                    <button
                                                        className={itemClass}
                                                        onClick={e =>
                                                            toggleSubMenu(
                                                                e,
                                                                item
                                                            )
                                                        }
                                                    >
                                                        <span className="inline-block">
                                                            {item.label}
                                                            <motion.span
                                                                initial={{
                                                                    rotate: 0,
                                                                }}
                                                                animate={{
                                                                    rotate:
                                                                        withChildren >
                                                                            0 &&
                                                                        activeMenu ===
                                                                            item.id
                                                                            ? 90
                                                                            : 0,
                                                                }}
                                                                exit={{
                                                                    rotate: 0,
                                                                }}
                                                                className={
                                                                    styles.littleArrow
                                                                }
                                                            />
                                                        </span>
                                                    </button>
                                                ) : item?.parentId == null ? (
                                                    // menu without children
                                                    <button
                                                        className={itemClass}
                                                        onClick={() =>
                                                            goTo(item.url)
                                                        }
                                                    >
                                                        {item.label}
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                                <AnimatePresence>
                                                    {withChildren > 0 &&
                                                        activeMenu ===
                                                            item.id && (
                                                            <motion.ul
                                                                className={
                                                                    styles.mobileMenuChildren
                                                                }
                                                                initial={
                                                                    initial
                                                                }
                                                                animate={
                                                                    animate
                                                                }
                                                                exit={exit}
                                                            >
                                                                {item?.childItems?.nodes.map(
                                                                    (
                                                                        child,
                                                                        c
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={`mc-${c}`}
                                                                            >
                                                                                <button
                                                                                    onClick={() =>
                                                                                        goTo(
                                                                                            child.url
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        child.label
                                                                                    }
                                                                                </button>
                                                                            </li>
                                                                        )
                                                                    }
                                                                )}
                                                            </motion.ul>
                                                        )}
                                                </AnimatePresence>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <button
                        className={styles.closeMobileMenu}
                        onClick={toggleMobileMenu}
                    >
                        Close
                    </button>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default MobileMenu
