// extracted by mini-css-extract-plugin
export var btn = "blocks-module--btn--KlPxa";
export var bigBtn = "blocks-module--bigBtn--2semh";
export var newsItem = "blocks-module--newsItem--xn_Xw";
export var readmore = "blocks-module--readmore--3gPXm";
export var numbers = "blocks-module--numbers--1XQPW";
export var numbersWrap = "blocks-module--numbersWrap--_WsJN";
export var numberItem = "blocks-module--numberItem--2G4aE";
export var numVal = "blocks-module--numVal--3_VJh";
export var numLabel = "blocks-module--numLabel--2uRAM";
export var gridItem = "blocks-module--gridItem--3eqhe";
export var fixedGridItem = "blocks-module--fixedGridItem--1W87J";
export var gridItemContent = "blocks-module--gridItemContent--3iivP";
export var catNavToggle = "blocks-module--catNavToggle--1kvRG";
export var catNav = "blocks-module--catNav--2KcQz";
export var catOpened = "blocks-module--catOpened--1Tu0e";