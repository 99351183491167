import React, { useState, useContext, useLayoutEffect, useEffect } from "react"
import { navigate } from "gatsby"
import classnames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import * as styles from "../styles/mobilemenu.module.css"
import { mainMenu } from "../utils/constants"
import { AppContext } from "../utils/context"
import { useAuthContext } from "../context/authContext"

const { initial, animate, exit } = mainMenu.animations

const MobileMenuSap = ({ activeMenuId, menuItems }) => {
    const [activeMenu, setActiveMenu] = useState()
    const { isMobileMenuVisible, setIsMobileMenuVisible } =
        useContext(AppContext)
    const { user, setUser } = useAuthContext()
    const [loggedIn, setLoggedIn] = useState(user !== null)

    const toggleSubMenu = (e, item) => {
        e.preventDefault()
        const newValue = item.id !== activeMenu ? item.id : null
        setActiveMenu(newValue)
    }
    const toggleMobileMenu = () => {
        setIsMobileMenuVisible(!isMobileMenuVisible)
    }
    const goTo = link => {
        setIsMobileMenuVisible(false)
        navigate(link)
    }
    // start SAP
    const sapKey = process.env.SAP_API_KEY

    useLayoutEffect(() => {
        // load SAP cdn at header
        ;(function loadSAPCdn() {
            var script = document.createElement("script")
            script.src = `https://cdns.gigya.com/js/gigya.js?apikey=${sapKey}`
            document
                .getElementsByTagName("script")[0]
                .parentNode.appendChild(script)
        })()
    }, [])

    useEffect(() => {
        function getAccountInfoResponse(response) {
            if (response.errorCode == 0) {
                // $("#lblName").text(msg);
                window.gigya.accounts.showScreenSet({
                    screenSet: "SML-ProfileUpdate",
                    startScreen: "gigya-update-profile-screen",
                    containerID: "mainProfile",
                })
                setLoggedIn(true)
                // $("#btnLogin").hide(); // convert to vanilla javascript
                // $("#btnRegister").hide(); // convert to vanilla javascript
            } else {
                //console.log('Error :' + response.errorMessage);
                //$("#lblName").text(response.statusMessage);
            }
            //console.log(response);
        }

        setTimeout(() => {
            window.gigya.accounts.getAccountInfo({
                callback: getAccountInfoResponse,
            })
            window.gigya.accounts.addEventHandlers({
                onLogin: eventData => {
                    setUser(eventData.profile)
                    navigate("/profile")
                },
            })
        }, 5000)
    }, [])

    useEffect(() => {
        setLoggedIn(user !== null)
    }, [user])

    const logoutResponse = response => {
        if (response.errorCode == 0) {
            navigate("/login")
            setUser(null)
        } else {
            alert("Error :" + response.errorMessage)
        }
    }

    const handlerLogout = () => {
        window.gigya.accounts.logout({
            callback: logoutResponse,
        })
    }

    // end SAP

    const itemClass = classnames(`block py-10 text-sm lg:text-base`, {
        "font-bold": false,
        "md:px-2 px-6": true,
        "md:pl-2 md:pr-8 pl-6 pr-10": false,
    })
    return (
        <AnimatePresence>
            {isMobileMenuVisible && (
                <motion.div
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: "100%", opacity: 0 }}
                    transition={{ type: "tween" }}
                    className={`block lg:hidden fixed inset-0 ${styles.mobileMenu}`}
                >
                    <div className="h-full px-8 pt-16 flex flex-col">
                        <div className="flex-1 overflow-y-auto">
                            <nav>
                                <ul>
                                    {loggedIn ? (
                                        <>
                                            <li>
                                                <button
                                                    className={itemClass}
                                                >
                                                    {`Hi ${user?.lastName},  Welcome back`}
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={itemClass}
                                                    onClick={() =>
                                                        goTo("/profile")
                                                    }
                                                >
                                                    Profile
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={itemClass}
                                                    onClick={handlerLogout}
                                                >
                                                    Logout
                                                </button>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li>
                                                <button
                                                    className={itemClass}
                                                    onClick={() =>
                                                        goTo("/login")
                                                    }
                                                >
                                                    Login
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={itemClass}
                                                    onClick={() =>
                                                        goTo("/register")
                                                    }
                                                >
                                                    Register
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={itemClass}
                                                    onClick={() =>
                                                        goTo("/subscribe")
                                                    }
                                                >
                                                    Subscribe
                                                </button>
                                            </li>
                                        </>
                                    )}
                                    {/* menuItems?.nodes.map((item, i) => {
                    const withChildren =
                        item?.childItems?.nodes?.length > 0
                    const itemClass = classnames(
                      `block py-10 text-sm lg:text-base`,
                      {
                        "font-bold": activeMenuId === item.id,
                        "md:px-2 px-6": !withChildren,
                        "md:pl-2 md:pr-8 pl-6 pr-10": withChildren,
                      }
                    )
                    return (
                      <li key={`mi-${i}`}>
                        {withChildren ? (
                          <button
                            className={itemClass}
                            onClick={e => toggleSubMenu(e, item)}
                          >
                            <span className="inline-block">
                              {item.label}
                              <motion.span
                                initial={{ rotate: 0 }}
                                animate={{
                                  rotate:
                                    withChildren > 0 && activeMenu === item.id
                                      ? 90
                                      : 0,
                                }}
                                exit={{ rotate: 0 }}
                                className={styles.littleArrow}
                              />
                            </span>
                          </button>
                        ) : item?.parentId == null ? (
                            // menu without children
                          <button
                            className={itemClass}
                            onClick={() => goTo(item.url)}
                          >
                            {item.label}
                          </button>
                        ) : ("")}
                      </li>
                    )
                  }) */}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <button
                        className={styles.closeMobileMenu}
                        onClick={toggleMobileMenu}
                    >
                        Close
                    </button>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default MobileMenuSap
