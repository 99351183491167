import * as React from "react"
import classnames from "classnames"
import * as styles from "../styles/blocks.module.css"

export const Button = ({ isFull, children, ...rest }) => {
  const btnClass = classnames("px-4 py-2", styles.btn, {
    "ml-4": !isFull,
    "w-full": isFull,
    [styles.btn]: !isFull,
    [styles.bigBtn]: isFull,
  })
  return (
    <button className={btnClass} {...rest}>
      {children}
    </button>
  )
}
